<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <!-- <a class="navbar-brand" routerLink={{homePage}}>
        <img src="assets/images/logo.png" style="width: 130px;" alt="" />
      </a> -->
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <i class="material-icons-outlined icon-color">
              menu
            </i>
          </button>
          
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="userType=='Location Manager'">
          <span style="padding: 7px;background-color: #1573DA;color: white;border-radius: 10px;">Location: {{myLocation}}</span>
        </li>
        <!-- Full Screen Button -->
       
        <!-- #END# Full Screen Button -->
        <!-- #START# Notifications-->
      
        <!-- #END# Notifications-->
       
        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <img src="../../../assets/images/user/admin.jpg" class="user_img" width="32" height="32" alt="User">
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <!-- <li>
                      <a href="javascript:void(0);" (click)="addDialog()">
                        <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>My Profile
                      </a>
                    </li> -->
                   
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
